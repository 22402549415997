<template>
  <div v-if="show" class="branded-loader">
    <div class="loader-content">
      <svg
        width="320"
        height="320"
        viewBox="0 0 3200 3200"
        preserveAspectRatio="xMidYMid"
        fill-rule="evenodd"
        stroke-width="28.222"
        stroke-linejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
      >
        <g>
          <path
            class="spin-1"
            fill="rgb(148,191,11)"
            stroke="none"
            d="M1492 1301L2245 337 1129 843 709 1523 1090 1148 1528 902 1185 1270 772 1527 1492 1301z"
          />
          <path
            class="spin-2"
            fill="rgb(106,132,26)"
            stroke="none"
            d="M1593 1993L2686 1716 1545 1444 788 1713 791 1715 1335 1651 1811 1722 1336 1799 908 1755 1593 1993z"
          />
          <path
            class="spin-3"
            fill="rgb(27,25,24)"
            stroke="none"
            d="M705 1888L1037 2426 1945 2839 1387 2124 848 1937 1098 2093 1372 2387 1022 2191 719 1893 705 1888z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style type="text/css" lang="scss" scoped>
.branded-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  z-index: 999;

  .loader-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 260px;
    height: 260px;
    margin: -130px 0 0 -130px;

    .spin-1 {
      transform-origin: center;
      animation: wave-rotate 0.8s ease-in-out infinite;
    }

    .spin-2 {
      transform-origin: center;
      animation: wave-rotate 0.8s ease-in-out infinite;
      animation-delay: 100ms;
    }

    .spin-3 {
      transform-origin: center;
      animation: wave-rotate 0.8s ease-in-out infinite;
      animation-delay: 200ms;
    }
  }
}
</style>
