<template>
  <div class="container-fluid design-preview">
    <div class="row">
      <template v-for="(item, i) in templates">
        <div class="col-md-6 template-item" :key="i">
          <div class="template-title large-font text-center">{{ item.template.title }}</div>
          <img :src="item.image" :alt="item.template.title" />
        </div>
      </template>
    </div>
    <div class="row">
      <div class="col-md-6">
        <button class="button text-color-white bg-light-green rounded-5 add-to-cart large-font" @click="confirm">
          Add to cart
        </button>
      </div>
      <div class="col-md-6">
        <button class="button rounded-5 large-font" style="color: #494b4d" @click="close">Continue editing</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Bus as VuedalsBus } from 'vuedals';

export default {
  name: 'ResultPreview',
  props: {
    data: Object,
    onConfirm: Function,
  },
  computed: {
    templates() {
      return this.data.templates;
    },
  },
  methods: {
    close() {
      VuedalsBus.$emit('close');
    },
    confirm() {
      if (typeof this.onConfirm === 'function') {
        this.onConfirm();
      }
      this.close();
    },
  },
};
</script>

<style type="text/css" lang="scss" scoped>
.design-preview {
  .template-item {
    .template-title {
      font-weight: bolder;
      color: #494b4d;
    }
    img {
      margin: 10px 0;
    }
  }
}
</style>
