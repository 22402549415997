<template>
  <div class="editor-layout" id="constructor">
    <header>
      <router-link to="/" class="logo-wrapper">
        <img src="../assets/images/logo.svg" alt="Vaporfly logo" width="180" height="30" />
      </router-link>
      <div class="center-block"></div>
      <div class="actions-block">
        <button class="button uppercase text-color-white bg-light-green rounded-5 add-to-cart" @click="exportData">
          Add to cart
        </button>
      </div>
    </header>
    <section class="content">
      <Editor v-if="ready" :templates="templates" ref="editor" anonymous :initial="inCartDesign">
        <div style="padding: 10px; margin-bottom: 15px; border-bottom: 1px solid #98a0b1;">
          <div>
            {{ product.title }}
          </div>
          <div style="margin-top: 10px">Minimal order quantity: {{ product.minimalQty }} pcs.</div>
          <div style="margin-top: 10px">Price: from ${{ product.price }} per item</div>
        </div>
      </Editor>
      <Loader v-else />
    </section>
  </div>
</template>

<script>
import { Bus as VuedalsBus } from 'vuedals';
import Editor from 'Components/editor/Editor';
import Loader from 'Components/Loader';
import ResultPreview from 'Components/editor/ResultPreview';
// http://vaporfly.dev.arkotik.com.ua/constructor/deepunion-e5-m1-0-5ml-cartridge
// http://vaporfly.dev.arkotik.com.ua/constructor/deepunion-trigger-vaporizer

export default {
  name: 'Constructor',
  components: { Loader, Editor },
  computed: {
    templates() {
      return this?.product?.templates || [];
    },
    slug() {
      return this.$route.params.slug;
    },
    ready() {
      return this.config && this.templates.length > 0;
    },
    inCartDesign() {
      if (!this.product) {
        return null;
      }
      return this.$store.state.cart.get(+this.product.id)?.design || null;
    },
  },
  methods: {
    load(slug) {
      this.config = false;
      this.$api
        .getProductBySlug(slug, false)
        .then(model => {
          this.product = model;
          this.config = true;
        })
        .catch(console.error);
    },
    async exportData() {
      const data = await this.$refs.editor.exportData();
      this.showPreview(data);
    },
    addToCart(design) {
      const { id, minimalQty: qty } = this.product;
      this.$store.dispatch('cartAdd', { id, qty, design });
      this.$router.push('/cart');
    },
    showPreview(data) {
      VuedalsBus.$emit('new', {
        name: 'design-preview',
        component: ResultPreview,
        props: { data, onConfirm: () => this.addToCart(data) },
        size: 'xl',
        escapable: true,
        closeOnBackdrop: true,
        dismissable: true,
        title: 'Design preview',
      });
    },
  },
  data() {
    return {
      product: null,
      config: false,
    };
  },
  created() {
    this.load(this.slug);
  },
};
</script>

<style type="text/css" lang="scss">
#constructor {
  --c-primary: #96c22e;
  --c-danger: #ef6969;
  background-color: #f0f2ef;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .button {
    height: initial;
  }

  *,
  :before,
  :after {
    box-sizing: border-box;
    font-family: arial, x-locale-body, sans-serif;
  }

  header {
    height: 50px;
    width: 100%;
    position: relative;
    background-color: white;
    box-shadow: 0 0 5px 0 #787878;
    display: flex;
    align-items: center;
    padding: 0 15px;

    & > div {
      height: 100%;
    }

    .logo-wrapper {
    }
    .center-block {
      flex-grow: 2;
    }
    .actions-block {
      align-self: flex-end;
      display: flex;
      flex-direction: row;
      align-items: center;

      .add-to-cart {
        padding: 0 15px;
        font-weight: bold;
        font-size: 1.1em;
        height: 35px;
      }
    }
  }

  section.content {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    .editor {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: calc(100vh - 50px);

      * {
        font-size: 16px;
      }

      .panel {
        position: relative;
        width: 320px;
        overflow: hidden;
        background-color: white;
        margin: 10px;
        border-radius: 4px;
        box-shadow: 0 0 8px 0 #787878;
        z-index: 5;

        &.left-panel {
          display: flex;
          flex-direction: column;

          .control-buttons {
            width: 100%;
            padding: 5px;
            box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.08);
            z-index: 2;
          }

          .items-list {
            display: flex;
            flex-direction: column;
            max-height: 100%;
            overflow-y: auto;
            z-index: 1;

            .item {
              display: flex;
              align-items: center;
              cursor: pointer;
              position: relative;
              border-bottom: 1px solid #bbbbbb;
              padding: 5px;

              &.active {
                background-color: hsl(71, 100%, 88%) !important;

                .thumb .text {
                  background: var(--c-primary);

                  svg {
                    fill: white;
                  }
                }
              }

              .thumb {
                align-self: stretch;
                margin: 5px;
                padding: 0;
                max-width: 48px;
                min-width: 48px;
                min-height: 48px;
                max-height: 48px;
                position: relative;
                border-radius: 4px;
                overflow: hidden;

                div {
                  width: 100%;
                  height: 100%;

                  &.image {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                  }

                  &.text {
                    background-color: #e3e4e8;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                      fill: #98a0b1;
                      width: 2em;
                      height: 2em;
                    }
                  }
                }
              }

              .title {
                text-transform: capitalize;
                flex-grow: 2;
                margin: 0 5px;
                font-size: larger;
              }

              .action-button {
                cursor: pointer;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 100%;

                &:last-child {
                  margin-right: 5px;
                }

                i {
                  display: inline-flex;
                  fill: currentColor;
                  position: relative;
                  pointer-events: none;

                  svg {
                    width: 1.5em;
                    height: 1.5em;

                    g {
                      transition: all 0.2s linear;
                    }
                  }
                }

                &.remove {
                  &:hover svg g {
                    fill: var(--c-danger);
                  }
                }
              }
            }
          }
        }

        &.right-panel {
          display: flex;
          flex-direction: column;

          .tools-handler {
            height: 100%;

            .tools-panel {
              display: flex;
              flex-direction: column;
              height: 100%;
            }
          }
        }
      }

      .editor-area {
        flex-grow: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: content-box;
        margin: 10px 0;
      }

      .buttons-group {
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        user-select: none;

        .button {
          border: 1px dotted #787878;
          padding: 10px 15px;
          width: 100%;
          text-align: center;
          cursor: pointer;

          &:first-child {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

          & + .button {
            border-left: none;
          }
        }

        & + .buttons-group {
          margin-top: 5px;
        }
      }

      .tabs {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;

        .tab-tile {
          width: 100%;
          display: flex;
          padding: 10px 15px;
          border-bottom: 1px solid #bbbbbb;
          position: relative;
          font-weight: bold;
          cursor: pointer;
          justify-content: center;

          & + .tab-tile:before {
            content: '';
            display: block;
            height: 100%;
            border-left: 1px solid #bbbbbb;
            position: absolute;
            left: 0;
            top: 0;
          }

          &.active {
            border-bottom: 2px solid var(--c-primary);
            color: var(--c-primary) !important;
          }
        }
      }

      .primary {
        color: var(--c-primary) !important;
        border-color: var(--c-primary) !important;

        &:hover {
          color: white !important;
          background-color: var(--c-primary) !important;
        }

        &-bg {
          background-color: var(--c-primary) !important;
        }
      }

      .danger {
        color: var(--c-danger) !important;
        border-color: var(--c-danger) !important;

        &:hover {
          color: white !important;
          background-color: var(--c-danger) !important;
        }

        &-bg {
          background-color: var(--c-danger) !important;
        }
      }

      button,
      input[type='button'],
      input[type='submit'],
      input[type='reset'],
      textarea {
        outline: none;
        user-select: none;

        &.action-button {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 24px;
          height: 24px;
          padding: 0;
          margin: 0;
          font-size: 16px;
        }
      }

      .status-bar {
        position: absolute;
        left: 340px;
        bottom: 10px;
        display: flex;
        flex-direction: row;

        .position,
        .size {
          line-height: 1;

          &:not(:last-child):after {
            content: '|';
            margin: 0 8px;
          }

          span {
            vertical-align: middle;
            font-family: monospace;

            &:first-child {
              margin-right: 10px;
            }
          }
        }
      }

      .editor-area {
        position: relative;
      }

      .template-switcher {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        width: 100px;
        display: flex;
        flex-direction: column;

        .template-variant {
          width: 100%;
          height: 100px;
          border-radius: 4px;
          background: #f0f2ef;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          //border: 1px solid transparent;
          box-shadow: 0 0 5px 0 #787878;

          &.active {
            //border: 1px solid var(--c-primary);
            box-shadow: 0 0 4px 1px var(--c-primary);
          }

          .tpl-title {
            text-align: center;
            font-size: smaller;
            font-weight: 500;
            padding: 2px 0;
          }

          &:not(:first-child) {
            margin-top: 10px;
          }

          .tpl-thumb {
            width: 90%;
            height: 90%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
</style>
